<template>
  <div class="home">
    <main>
      <div class="container">
        <div class="row text-center">
          <div class="col">
            <div
              class="
                col-12 col-sm-10
                offset-sm-1
                col-md-8
                offset-md-2
                col-lg-6
                offset-lg-3
              "
            >
              <h3 class="jumbotron-heading">
                Ciao {{ this.name }}, da questa pagina potrai accedere al test
                <img
                  src="../assets/logo_motiva.webp"
                  alt="logo motiva"
                  style="height: 30px"
                  class="pb-1"
                />
                <span class="align-top" style="font-size: 1rem">&copy;</span>*
              </h3>
            </div>
          </div>
        </div>

        <div class="row" style="padding-top: 3rem">
          <div
            class="
              col-12 col-sm-10
              offset-sm-1
              col-md-8
              offset-md-2
              col-lg-6
              offset-lg-3
              text-center
            "
          >
            <span class="pb-3">
              Inserisci il codice fiscale per poter accedere al test.
            </span>
          </div>
        </div>

        <div class="row">
          <div
            class="
              col-12 col-sm-10
              offset-sm-1
              col-md-8
              offset-md-2
              col-lg-6
              offset-lg-3
            "
          >
            <input
              v-model="taxCode"
              type="text"
              class="form-control"
              id="taxCodeInput"
              aria-describedby="taxCodeHelp"
              placeholder="Codice fiscale"
            />
          </div>
        </div>

        <div class="pt-4 pb-5 buttonFrame">
          <!-- <button
              class="btn"
              v-on:click="taxCheck"
              :disabled="!isLoginActive"
            >
              Accedi
            </button> -->
          <button
            class="btn_cta"
            v-on:click="taxCheck"
            :disabled="!isLoginActive"
          >
            <span class="textButt">Accedi</span>
            <span class="plusButt">&#8594;</span>
            <!-- &#43;	 -->
          </button>
        </div>
        <!-- goCourseTest taxCheck -->

        <!-- <div class="row">
          <div
            class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-left"
          >
            <h3 class="pb-2 jumbotron-heading text-left">
              Che cos'è e come funziona
            </h3>

            <p class="lead text-muted text-justify">
              È un <b>test di orientamento professionale</b> online che indaga
              le <b>motivazioni</b>, le <b>attitudini</b>, gli
              <b>interessi</b> e i <b>punti di forza</b> di ciascuno.
            </p>
          </div>
        </div>

        <div class="row">
          <div
            class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-left"
          >
            <h3 class="pb-2 jumbotron-heading text-left">Risultato del test</h3>

            <p class="lead text-muted text-justify">
              L'esito è un elenco di <b>professioni adatte</b> al proprio
              profilo, ordinate secondo la maggiore o minore corrispondenza alle
              proprie caratteristiche, con l'indicazione delle competenze
              connesse. L’output restituisce non solo le professioni più in
              linea, ma le tipologie di ente o azienda più adatte al tuo
              profilo. <br />
              Il test richiede circa 30 minuti ed è composto da
              <b>domande di approfondimento</b> che indagano varie aree della
              personalità e delle attitudini.
            </p>
          </div>
        </div> -->
        <div class="row px-2 px-sm-0 pb-5">
          <div class="col">
            <h2 class="jumbotron-heading text-center">
              <strong>Test e percorso di orientamento Motiva</strong>
            </h2>
          </div>
        </div>

        <div class="row px-3 px-sm-0 mainContent">
          <div class="col-12 col-md-6">
            <div class="row">
              <h3 class="pr-md-5 pb-2 jumbotron-heading text-left">
                <strong
                  >Diventa esperto di te stesso e prepara il tuo futuro</strong
                >
              </h3>
            </div>

            <div class="row">
              <p class="pr-md-5 lead text-muted text-justify">
                Il test e percorso di orientamento Motiva è uno strumento di
                auto-coaching che ti permette di prendere consapevolezza delle
                tue motivazioni, attitudini e competenze, e ti aiuta così a
                individuare la professione più adatta a te e la formazione più
                utile.
              </p>
            </div>
            <div class="row">
              <h3 class="pr-md-5 jumbotron-heading text-left">
                <strong>Il percorso comprende</strong>
              </h3>

              <ul class="pr-md-5 lead text-muted text-justify pl-3">
                <li>
                  - <strong>video</strong> introduttivi e di spiegazione dei
                  risultati
                </li>
                <li>- un <strong>test di 20/25 minuti</strong> su 5 aree</li>
                <li>
                  - attività di <strong>riflessione guidata</strong> sui
                  risultati ottenuti
                </li>
                <li>- ampio database con <strong>1200+ professioni</strong></li>
                <li>
                  - link a <strong>7000+ siti web</strong> per indagare le
                  professioni
                </li>
                <li>- <strong>risultati istantanei</strong> online</li>
              </ul>
            </div>
          </div>

          <div class="col-12 col-md-6 pt-3 pt-md-0">
            <div class="row">
              <h3 class="pl-md-5 jumbotron-heading text-left">
                <b>Che cos'è e come funziona</b>
              </h3>
            </div>

            <div class="row">
              <p class="pl-md-5 lead text-muted text-justify">
                È un <b>test di orientamento professionale</b> online che indaga
                le <b>motivazioni</b>, le <b>attitudini</b>, gli
                <b>interessi</b> e i <b>punti di forza</b> di ciascuno.
              </p>
            </div>

            <div class="row">
              <h3 class="pl-md-5 jumbotron-heading text-left">
                <b>Risultato del test</b>
              </h3>
            </div>
            <div class="row">
              <p class="pl-md-5 lead text-muted text-justify">
                L'esito è un elenco di <b>professioni adatte</b> al proprio
                profilo, ordinate secondo la maggiore o minore corrispondenza
                alle proprie caratteristiche, con l'indicazione delle competenze
                connesse. L&apos;output restituisce non solo le professioni più in
                linea, ma le tipologie di ente o azienda più adatte al tuo
                profilo.
                <!-- <br />
                Il test richiede circa 30 minuti ed è composto da
                <b>domande di approfondimento</b> che indagano varie aree della
                personalità e delle attitudini. -->
              </p>
            </div>
          </div>
        </div>

        <div class="row text-right">
          <div class="col pt-4"><h6>* powered by Pearson</h6></div>
        </div>
      </div>
    </main>
  </div>

  <footer class="foot">
    <div class="container">
      <div class="row">
        <div class="col logoBianco">
          <img src="../assets/logo_bianco.png" alt="logo habacus bianco" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          Habacus è una data-driven company ad impatto sociale che sostiene la
          formazione post-diploma; un integratore di sistema che mette in rete
          studenti, enti formativi, finanza privata e istituzioni pubbliche.
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-md-12">
          <div class="contact">
            Vuoi ricevere maggiori informazioni sui nostri servizi? <br />
            Scrivici a
            <a href="mailto:info@habacus.com" style="color: #fff"
              >info@habacus.com</a
            >
          </div>
          <div class="icon-social">
            <a
              href="https://www.facebook.com/habacus.srl/"
              target="_blank"
              rel="noopener noreferrer"
              ><svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 320 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                ></path></svg
            ></a>
          </div>
          <div class="icon-social">
            <a
              href="https://www.instagram.com/habacus_ita/"
              target="_blank"
              rel="noopener noreferrer"
              ><svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 448 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                ></path></svg
            ></a>
          </div>
          <div class="icon-social">
            <a
              href="https://www.linkedin.com/company/h-abacus/"
              target="_blank"
              rel="noopener noreferrer"
              ><svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 448 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                ></path></svg
            ></a>
          </div>
          <div class="icon-social">
            <a
              href="https://www.youtube.com/channel/UCmOWrnZDTuuvOYDrQdDZ2Cw"
              target="_blank"
              rel="noopener noreferrer"
              ><svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 576 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                ></path></svg
            ></a>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-md-12 note">
          Habacus&nbsp;srl, Sede legale: Via Filippo Sassetti, 32, 20124 Milano
          (MI), Italia <br />
          C.F. e numero di iscrizione presso il Registro delle Imprese di Milano
          Monza Brianza Lodi 04906910262 <br />
          Habacus srl &copy; 2022
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from "axios";
import store from "@/store";
import { CONFIG, URL_GET_NAME, URL_LOGIN, URL_GET_TOKEN } from "../utils/variables";

export default {
  name: "Home",
  data: function () {
    return {
      isLoginActive: false,
      name: "",

      tag: "POSTE_ITALIANE",

      uuid: null,

      taxCode: null,

      // showButton: true,
    };
  },

  mounted() {
    // const uuid = params.get("uuid");
    const params = new URLSearchParams(window.location.search);
    const self=this;
    self.uuid = params.get("uuid");
    axios
      .get(
        URL_GET_TOKEN
      )
      .then(
        (response) => {
          const hppHeader = {
            hpp: response.data.token
          };

          CONFIG.headers = Object.assign(CONFIG.headers, hppHeader)

          

          // this.passEventEndButton();
          this.visibilityButt();
          try {
            if (window.location.search != null) {
              // this.uuid = params.get("uuid");
            }
          } catch (exception) {
          }
          const c = Object.assign(CONFIG, {
                params: {
                  tag: this.tag,
                  uuid: self.uuid,
                },
              });
          axios
            .get(
              URL_GET_NAME,
              // CONFIG,
              c
              
            )
            .then((response) => {
              if (response.status == 200) {

                if (response.data.status == "OK") {
                  this.name = response.data.result;
                } else {
                  this.name = "utente";
                }
              } else {
                this.name = "utente";
              }
            })
            .catch(() => {
              this.name = "utente";
            });


        }
      );

  },

  watch: {
    taxCode: function (val) {
      if (val.length == 16) {
        this.isLoginActive = true;
      } else {
        this.isLoginActive = false;
      }
    },
  },

  methods: {
    // passEventEndButton() {
    //   this.$emit("showHideButton", false);
    // },

    visibilityButt() {
      document.getElementById("buttonEnd").style.visibility = "hidden";
    },

    taxCheck() {
      //console.log("taxCheck");
      const c = Object.assign(CONFIG,
          {
            params: {
              tag: this.tag,
              uuid: this.uuid,
              taxCode: this.taxCode,
            },
          });
      axios
        .get(
          URL_LOGIN,
          c,
        )
        .then((response) => {
          //console.log(response);

          if (response.status == 200) {
            //console.log("response.status 200");
            // //console.log(response.status);
            // //console.log(response.data.status);
            // //console.log(response.data.result);

            if (response.data.status == "OK" && response.data.result == true) {
              store.state.authorized = true;
              this.$router.push({
                name: "Launcher",
                query: { uuid: this.uuid },
              });
              //console.log("result is true from Home");
            } else {
              //console.log("result is false from Home");
            }
          } else {
            //console.log("response.status is not 200");
          }
        });
    },
  },
};
</script>

<style>
#buttonEnd {
  border: none;
  background-image: linear-gradient(135deg, #fff, #fff);
}
</style>