<template>
  <div class="about">
    <main>
      <div class="">
        <div
          class="row text-center"
          v-if="!isWarningActive"
          id="spinnerContainer"
          v-show="isToShow"
        >
          <div class="col">
            <div class="spinner-grow text-primary" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        </div>

        <div
          class="row text-center warning"
          v-if="isWarningActive"
          v-show="isToShow"
        >
          <div class="col">
            <h1>Tentativo di accesso non autorizzato.</h1>
          </div>
        </div>

        <!-- <Modal v-show="isModalVisible" @close="closeModal" /> -->

        <div class="form" id="myDIV"></div>

        <iframe
          id="myIFRAME"
          name="myIFRAME"
          class="iframeStyle"
          v-show="isToHide"
        ></iframe>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import {
  CONFIG,
  DELAY_MINUTES,
  HOW_LONG_WILL_YOU_SPIN,
  URL_IS_AUTHORIZED,
  URL_START_COURSE,
  URL_GET_TOKEN
} from "../utils/variables";
//import func from 'vue-editor-bridge';

export default {
  name: "Launcher",

  data: function () {
    return {
      isWarningActive: false,
      isButtonActive: false,
      tag: "POSTE_ITALIANE",
      uuid: null,
      isToShow: true,
      isToHide: false,
      isModalVisible: false,
      isSafari: false,

      // counter: 10,
      // showButton: true,
    };
  },

  mounted() {


    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


    setTimeout(this.visibilityButt, DELAY_MINUTES * 60 * 1000);
    setTimeout(this.spinnerFun, HOW_LONG_WILL_YOU_SPIN);
    try {
      if (window.location.search != null) {
        const params = new URLSearchParams(window.location.search);
        this.uuid = params.get("uuid");
      }
    } catch (exception) {
      console.error;
    }

    // document.getElementByClass("upperStuff").style.display = "none";

    axios
      .get(
        URL_GET_TOKEN
      )
      .then((response)=>{
        
    axios
      .get(
        URL_IS_AUTHORIZED,
        Object.assign(Object.assign(CONFIG, {hpp:response.data.token}),{
          params: {
            tag: this.tag,
            uuid: this.uuid,
          },
        } )
      )
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status == "OK" && response.data.result == true) {
            this.isButtonActive = true;
            this.isWarningActive = false;
            //console.log(response);

            this.apiPostFunc();
            // setTimeout(this.apiPostFunc, 10000);
          } else {
            this.isButtonActive = false;
            this.isWarningActive = true;
          }
        } else {
          this.isButtonActive = false;
          this.isWarningActive = true;
        }
      });
    //needed to load page from the top and not from the button position from Home.vue view
    
    })




    window.scrollTo(0, 0);
  },

  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },

    visibilityButt() {
      document.getElementById("buttonEnd").style.visibility = "visible";
    },
    apiPostFunc() {

    axios
      .get(
        URL_GET_TOKEN
      )
      .then((response)=>{

      axios
        .get(
          URL_START_COURSE,
          Object.assign(Object.assign(CONFIG, {hpp:response.data.token}),{
          params: {
            target: this.isSafari ? "_blank" : "myIFRAME",
            tag: this.tag,
            uuid: this.uuid,
          },
        } )
        )
        .then((response) => {
          const html = response.data.result.html;
          // const action = response.data.result.action;
          // const target = response.data.result.target;

          // let intoIframe = true;
          // if (intoIframe) {
          //   const iframe = document.getElementById("myIFRAME");
          //   // iframe.setAttribute("name", target);
          //   // iframe.setAttribute("src", action);
          // }
          document.getElementById("hiddenForm")?.remove();
          const newDiv = document.createElement("div");
          newDiv.setAttribute("id", "hiddenForm");
          newDiv.innerHTML = html;
          document.getElementById("myDIV").appendChild(newDiv);

          newDiv.querySelector("input[type=submit]").form.submit();
          // this.isToShow = false;
          // this.isToHide = true;
        });
    
      });
    
    
    },

    spinnerFun() {
      this.isToShow = false;
      this.isToHide = true;
    },

    // countdown(counter) {
    //   if (counter > 0) {
    //     counter--;
    //     setTimeout(function () {
    //       this.countdown(counter);
    //     }, 1000);
    //     counter;
    //   }
    // },
  },
};
</script>


<style>
#buttonEnd {
  color: #003da6;
}

#buttonEnd:hover {
  color: #003da6;
  text-decoration: underline;
}
</style>
